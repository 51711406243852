export const dark = {
    dark: true,
    colors: {
        backButtonLabel: '#fff',
        background: '#000',
        backgroundTransparent: '#00000000',
        border: '#aaa',
        buttonLabel: '#fff',
        buttonText: '#fff',
        buttonDefault: '#00a',
        buttonLabel: '#fff',
        buttonPrimary: '#e97451',
        buttonPrimaryLabel: '#fff',
        buttonSecondary: '#aaf',
        buttonDanger: '#f00',
        buttonDisabled: '#aaa',
        brandDark: '#aaa',
        brandLight: '#fff',
        headerPrimary: '#ddd',
        headerSecondary: 'yellow',
        screen: '#111',
        screenTitleBackground: '#333333',
        shadow: '#fff',
        tabActive: '#f00',
        tabInactive: '#fff',
        tabBackground: '#000',
        textDefault: '#fff',
        textAlt: '#000',
        userTitle: '#fff',
        modalBackground: '#000',
        inputLabel: '#fff',
        inputText: '#fff',
        inputTextFocused: '#000',
        inputBackground: '#000',
        inputBackgroundFocused: '#fff',
        inputPlaceholderText: '#333',
        inputPlaceholderTextFocused: '#333',
        quantityBackground: '#fff',
        quantityLabel: '#000',
        statusOn: '#afa',
        statusOff: '#fff',
    },
}

export const light = {
    dark: false,
    colors: {
        backButtonLabel: '#000',
        background: '#fff',
        backgroundTransparent: '#fffff00',
        border: '#aaa',
        buttonText: '#fff',
        buttonDefault: '#00a',
        buttonPrimary: '#e97451',
        buttonLabel: '#000',
        buttonPrimaryLabel: '#fff',
        buttonSecondary: '#aaf',
        buttonDanger: '#f00',
        buttonDisabled: '#aaa',
        brandDark: '#aaa',
        brandLight: '#000',
        headerPrimary: '#333',
        headerSecondary: 'orange',
        screen: '#fff',
        screenTitleBackground: '#dddddd',
        shadow: '#000',
        tabActive: '#f00',
        tabInactive: '#000',
        tabBackground: '#fff',
        textDefault: '#000',
        textAlt: '#fff',
        userTitle: '#000',
        modalBackground: '#fff',
        inputLabel: '#000',
        inputText: '#0a0',
        inputTextFocused: '#000',
        inputBackground: '#fff',
        inputBackgroundFocused: '#fee',
        inputPlaceholderText: '#fcc',
        inputPlaceholderTextFocused: '#fcc',
        quantityBackground: '#000',
        quantityLabel: '#fff',
        statusOn: 'tomato',
        statusOff: '#000',
    },
}